/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <ValidationObserver ref="observer" class="row w-100 m-0">
                  <div class="col-md-12 mb-3">
                    <label for="validation_name">{{cvUnivNameLabel}}<span v-if="propUnivObj">: {{ propUnivObj.org_name }}</span></label>
                      <multiselect
                      v-if="!propUnivObj"
                      v-model="organisation"
                      :options="cvUniversityOptions"
                      :placeholder="cvSelectBoxText +' '+ cvUnivLabel"
                      :custom-label="orgNameWithCityCountry"
                      track-by="org_id"
                      @select="toggleUniversity">
                      </multiselect>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_id">{{cvUnivIdLabel}} <span v-if="propUnivObj" style="font-size: 12px;">: {{ propUnivObj.org_id }}</span></label>
                    <input v-if="!propUnivObj" v-model="vmUniversityCourseFormData.org_id" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_degree_name">{{cvCourDegreeNameLabel}} <span class="primary-color font-size-16" style="line-height: 0px;">*</span></label>
                    <ValidationProvider :name="cvCourDegreeNameLabel"  v-slot="{ errors }" rules='required' ref="courseAdd">
                      <input v-model="vmUniversityCourseFormData.cour_degree_name" type="text" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_name">{{cvCourNameLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span></label>
                    <ValidationProvider :name="cvCourNameLabel"  v-slot="{ errors }" rules='required' ref="courseAdd">
                      <input v-model="vmUniversityCourseFormData.cour_name" type="text" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationurl">{{cvCourUrl}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span></label>
                    <ValidationProvider :name="cvCourUrl"  v-slot="{ errors }" rules='required' ref="courseAdd">
                      <input v-model="vmUniversityCourseFormData.url" type="text" class="form-control" required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_desc">{{cvCourDescLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_desc" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_curr">{{cvCourCurrLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_curr" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_tuition_min">{{cvCourTuitionMinLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_tuition_min" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_tuition_max">{{cvCourTuitionMaxLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_tuition_max" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_scholarship_min">{{cvCourScholarshipMinLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_scholarship_min" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_scholarship_max">{{cvCourScholarshipMaxLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_scholarship_max" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationcour_adm_cycle">{{cvCourAdmCycleLabel}}</label>
                    <input v-model="vmUniversityCourseFormData.cour_adm_cycle" type="text" class="form-control" required />
                  </div>
                </ValidationObserver>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="organisationCourseAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { UniversityCourses } from "../../../FackApi/api/universityCourse.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import { Organisations } from "../../../FackApi/api/organisation.js"
import { ValidationProvider, ValidationObserver } from "vee-validate"

export default {
  name: "UniversityCourseAdd",
  components: {
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUnivObj: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      cvCardTitle: "Add Organisation Course",
      cvCardSubHeader: "Add Organisation Course",
      cvSubmitBtn: "Add",
      cvUnivNameLabel: "Organisation name",
      cvUnivIdLabel: "Organisation Id",
      cvCollegeIdLabel: "college id",
      cvCourDegreeNameLabel: "Degree Name",
      cvCourNameLabel: "Course Name",
      cvCourDescLabel: "Description",
      cvCourCurrLabel: "Currency",
      cvCourTuitionMinLabel: "Tuition Fees Min",
      cvCourTuitionMaxLabel: "Tuition Fees Max",
      cvCourScholarshipMinLabel: "Scholarship Min",
      cvCourScholarshipMaxLabel: "Scholarship Max",
      cvCourAdmCycleLabel: "Admission Cycle",
      cvCourUrl: "Course Link",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "UniversityCourse",
      vmUniversityCourseFormData: Object.assign({}, this.initFormData()),
      vmUniversityCourseDesc: null,
      vmUniversityCourseType: null,
      cvUniversityOptions: [],
      cvUnivLabel: "Organisation",
      cvSelectBoxText: "Start Typing",
      organisation: ""
    }
  },
  mounted () {
    this.organisationList()
  },
  methods: {
    /**
     * Toggle Organisation is used in dropdown list for selecting organisation from list
     */
    toggleUniversity (org) {
      this.vmUniversityCourseFormData.org_id = org.org_id
      this.vmUniversityCourseFormData.org_name = org.org_name
    },
    /*eslint-disable */
    orgNameWithCityCountry ({ org_name, org_city, org_country }) {
      return `${org_name}${org_city ? ", " + org_city : ""}${org_country ? ", " + org_country : ""}`
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "org_name": "",
        "org_id": "",
        "college_id": "",
        "cour_degree_name": "",
        "cour_name": "",
        "cour_desc": "",
        "cour_curr": "",
        "cour_tuition_min": "",
        "cour_tuition_max": "",
        "cour_scholarship_min": "",
        "cour_scholarship_max": "",
        "cour_adm_cycle": "",
        "url": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUniversityCourseFormData) {
          if (!this.vmUniversityCourseFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseAdd
     */
    async organisationCourseAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      const isValid = await this.$refs.courseAdd.validate()
        console.log("valid", isValid)
        if (!isValid.valid) {
          return
        }

      try {
        this.cvLoadingStatus = true
        if(this.propUnivObj){
          this,this.vmUniversityCourseFormData.org_name =  this.propUnivObj.org_name
          this,this.vmUniversityCourseFormData.org_id =  this.propUnivObj.org_id
        }

        let organisationCourseAddResp = await UniversityCourses.organisationCourseAdd(this, this.vmUniversityCourseFormData)
        await ApiResponse.responseMessageDisplay(this, organisationCourseAddResp)

        if (organisationCourseAddResp && !organisationCourseAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUniversityCourseAddModal", organisationCourseAddResp.resp_data)
        }
        this.vmUniversityCourseFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * Organisation Lists
     */
    async organisationList () {
      const filter = {
        type: ["UNIV"]
      }

      let orgResp = await Organisations.organisationList(this, filter)
      if (orgResp.resp_status) {
        this.cvUniversityOptions = Object.values(orgResp.resp_data.data)
      }
    }
  }
}
</script>
