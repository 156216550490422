<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col md="12" class="heading_alignment">
        <!-- Univ Course selection Model -->
        <b-modal class="xxl" :title="univCourseSelectionModalTitle" v-model="showUnivCourseSelectionModal" no-close-on-backdrop no-close-on-esc @hide="closeUnivCourseSelectionModal">
          <form action="#">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <b-button variant="primary" @click="addCourseToUniv(univCourseObj)" class="pull-right mr-2 mb-1 btn-primary">Add Program/Course</b-button>
                <h5>{{ univCourseObj.org_name }}</h5>
              </div>

              <div class="col-md-12 mb-3" v-if="univCourseObjectList">
                <label for="univCourSelectCourseNameLabel">{{ cvUniversityCourseNameLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                <select id="univCourSelectCourseNameLabel" v-model="univCourseObj.cour_id" class="form-control">
                  <option disabled :value="null">Select Course/Program</option>
                  <option :value="course.cour_id" v-for="(course) in univCourseObjectList" :key="course.cour_id">{{course.cour_name}}</option>
                </select>
                <label><small class="primary-color">Recommended courses will not appear in the list for students</small></label>
              </div>

              <div v-if="univCourseObjectList" class="col-md-12 mb-3">
                <label v-if="vmOrganisationFormData.org_application_deadline_internal">Current Application Deadline (Internal): <b><span v-html="vmOrganisationFormData.org_application_deadline_internal"></span></b></label><br>
                <label>Set New {{cvOrgDeadlineInternalLabel}}</label>
                <input
                  v-model="vmOrganisationFormData.org_application_deadline_internal"
                  type="date"
                  class="form-control"
                  required
                />
              </div><br>
              <button v-if="univCourseObjectList" type="button" class="btn btn-primary ml-1" @click="addUnivToAdmission()">Recommend</button>
            </div>
          </form>
          <template #modal-footer="">
            <b-button size="sm" class="pull-left" variant="secondary" @click="closeUnivCourseSelectionModal()">
              {{modalCancelBtnText}}
            </b-button>
          </template>
        </b-modal><!-- Univ Course selection Model -->

        <!-- University Course Add Modal -->
        <b-modal v-model="showModelUniversityCourseAdd" scrollable :title="cvAddModalHeader" size="xl">
          <UniversityCourseAdd :propOpenedInModal="true" :propUnivObj="UnivObj"
            @emitCloseUniversityCourseAddModal="closeUniversityCourseAddModal" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" variant="secondary" @click="closeUniversityCourseAddModal()">
                {{modalCancelBtnText}}
              </b-button>
            </template>
        </b-modal><!-- University Course Add Modal -->

        <!-- Add Edit Internal And External Model -->
        <b-modal class="xxl" :title="univDeadlineTitle" v-model="showAddEditDeadlineModal" no-close-on-backdrop no-close-on-esc @hide="closeAddEditDeadlineModalnModal">
          <form action="#">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label>{{cvOrgDeadlineLabel}}</label>
                <textarea placeholder='' v-model="vmOrganisationFormData.org_application_deadline" type="text" class="form-control textarea"></textarea>
              </div><br>

              <div class="col-md-12 mb-3">
                <label>{{cvOrgDeadlineInternalLabel}}</label>
                <input
                  v-model="vmOrganisationFormData.org_application_deadline_internal"
                  type="date"
                  class="form-control"
                  required
                />
              </div><br>

              <button type="button" class="btn btn-primary ml-1" @click="organisationEditDeadline()">Add Deadline</button>
            </div>
          </form>
          <template #modal-footer="">
            <b-button size="sm" class="pull-left" variant="secondary" @click="closeAddEditDeadlineModalnModal()">
              {{modalCancelBtnText}}
            </b-button>
          </template>
        </b-modal><!-- Add Edit Internal And External Mode -->

        <iq-card style="padding:10px" class="pt-2">
          <template v-slot:headerTitle>
            <div class="row">
              <div class="col-12 col-sm-8 col-md-8 col-lg-10">
                <h4 v-if="propSpcmObj" @click="gotoUserProfile(propSpcmObj)" class="card-title p-0">
                  {{ propSpcmObj.user_name }}'s {{ cvCardTitle }} Shortlist
                </h4>
                <h4 v-else class="card-title p-0">
                  Recommend Universities
                </h4>
                <small small v-if="propSpcmObj">{{ propSpcmObj.user_id }}</small>
              </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-2">
              <b-button class="pull-right" v-if="propSpcmObj" variant="primary" @click="openUnivListModal()">
              {{cvbtnAddNewReco}}
              </b-button>
              <b-button class="pull-right" v-else variant="primary" @click="goToOrgAdd()">
                {{cvbtnAddNewUniv}}
              </b-button>
            </div>
          </div>
          </template>
          <template>
            <b-row class="mt-1">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3">
                <!-- Frontend Search -->
                <!-- <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> --><!-- Frontend Search -->

                <!--Backend Search  -->
                <input class="form-control"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchFromBackend"
                  :placeholder="cvSearchText" /><!--Backend Search  -->
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.country" class="form-control" @change="setFilters" title="Choose Country">
                  <option :value="null" disabled>Choose Country</option>
                  <option :value=null>All</option>
                  <option v-for="(country, index) of univFiltersList['org_country']" :key="(index+1)" :value="country.org_country">
                    {{country.org_country}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.is_partner" class="form-control" @change="setFilters" title="Org Partnership Type">
                  <option :value="null" disabled>Choose University Partner type</option>
                  <option :value=null>All</option>
                  <option v-for="(orgpartnerType, index) of univFiltersList['is_partner']" :key="(index+1)" :value="orgpartnerType.is_partner">
                    {{orgpartnerType.is_partner}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.cour_name" class="form-control" @change="setFilters" title="Course Name">
                  <option :value="null" disabled>Choose Course Name</option>
                  <option :value=null>All</option>
                  <option v-for="(course, index) of courseNameList" :key="(index+1)" :value="course.cour_name">
                    {{course.cour_name}}
                  </option>
                </select>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="univObjList && univObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="univObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="perPage">
                  <template v-slot:cell(org_name)="data">
                    <b><span class="pointer" @click="openUnivDetails(data.item)">{{ data.item.org_name }}</span></b><br>
                    <small>
                      {{ data.item.org_city ? `${data.item.org_city}` : "" }}{{ data.item.org_state ? `, ${data.item.org_state}` : "" }}{{ data.item.org_country ? `, ${data.item.org_country}` : "" }}<br>
                      Id:&nbsp;{{ data.item.org_id }}&nbsp;&nbsp;
                    </small><br><br>
                    <b-button v-if="!propSpcmObj" class="btn btn actionIcon mr-1 btn-secondary btn-sm" @click="openUnivCourseSelectionModal(data.item)" size="sm" title="Recommend University"><i class="fa-solid fa-heart-circle-plus m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-18 mr-1 btn-secondary btn-sm" @click="goToOrgEdit(data.item)" size="sm" title="Edit University"><i class="fa-solid fa-pencil m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-18 mr-1 btn-secondary btn-sm" @click="goToOrganisationCourses(data.item)" size="sm" title="Courses"><i class="fa fa-book m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-18 mr-1 btn-secondary btn-sm" @click="gotoActivityURL(data.item.org_url)" size="sm" title="Activity"><i class="fa-brands fa-chrome m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                  </template>
                  <template v-slot:cell(org_drs)="data">
                    {{ data.item.org_drs.toUpperCase() }}
                  </template>
                  <template v-slot:cell(org_application_deadline)="data">
                    <span v-html="getApplicationDeadlines(data.item)"></span><br>
                    <small>
                      <span>Internal Deadline:</span>&nbsp;
                      <span v-if="data.item.org_application_deadline_internal" class="primary-color pointer" title="Edit Deadline" @click="openInternalAndExternalDeadline(data)">{{ data.item.org_application_deadline_internal|dateFormatDDMMYYYY }}</span>
                      <span v-else class="primary-color pointer" title="Edit Deadline" @click="openInternalAndExternalDeadline(data)"><b-button class="btn btn actionIcon font-size-18 mr-1 btn-secondary btn-sm" @click="openInternalAndExternalDeadline(data)" size="sm" title="Edit Deadline"><i class="fa-solid fa-calendar-days m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button></span>
                    </small>
                  </template>
                  <template v-slot:cell(scholarship_id)="data">
                    {{data.item.scholarship_id ? data.item.scholarship_id.split(",").length : 0}}
                  </template>
                  <template v-slot:cell(org_acceptance_rate)="data">
                    {{ getAcceptanceRate(data.item.org_acceptance_rate) }}
                  </template>
                  <template v-slot:cell(org_avg_tuition_min)="data">
                    {{ data.item.org_avg_tuition_min ? `${data.item.org_avg_tuition_min} - ${data.item.org_avg_tuition_max}` : "N/A" }}
                  </template>
                  <template v-slot:cell(org_sat_min)="data">
                    {{ data.item.org_sat_min ? `${data.item.org_sat_min} - ${data.item.org_sat_max}` : "TEST BLIND" }}
                  </template>
                  <template v-slot:cell(org_act_min)="data">
                    {{ data.item.org_act_min ? `${data.item.org_act_min} - ${data.item.org_act_max}` : "TEST BLIND"}}
                  </template>
                  <template v-slot:cell(cour_name)="data">
                    <span>{{ data.item.cour_degree_name }}</span><br />
                    <span>{{ data.item.cour_name }}</span><br />
                    <div v-if="data.item.cour_url">
                      <b-button class="btn btn actionIcon font-size-18 mr-1 btn-secondary btn-sm" @click="goToUnivCoursePage(data.item.cour_url)" size="sm" title="Course Page"><i class="fa-brands fa-chrome font-size-20 primary-color"></i></b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                <span v-if="showDefaultForRecommedUnivList">
                  We did not find any shortlisted universities. <span class="pointer primary-color" @click="openUnivListModal()">Click here</span> to recommend universities.
                </span>
                <span v-else>
                  We did not find any matching universities.
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
                <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentBatchNo"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelOrganisationAdd"
      scrollable
      :title="cvAddUniAddHeader"
      size="xxl"
    >
      <UniversityAdd :propOpenedInModal="true" @emitCloseOrganisationAddModal="closeOrganisationAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeOrganisationAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelOrganisationEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xxl"
    >
      <UniversityEdit :propOpenedInModal="true" :propOrganisationObj="organisationEditObj" @emitCloseOrganisationEditModal="closeOrganisationEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeOrganisationEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelUniversityCourseList"
      scrollable
      :title="cvUniversityCourseHeader"
      size="xxl"
    >
      <UniversityCourseList :propOpenedInModal="true" :propUnivDetail = "organisationCourseObj" @emitUniversityCourseListModal="closeUniversityCourseListModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeUniversityCourseModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Organisations } from "../../../FackApi/api/organisation.js"
import { UniversityCourses } from "../../../FackApi/api/universityCourse.js"
import moment from "moment"
import { socialvue } from "../../../config/pluginInit.js"
import { Admissions } from "../../../FackApi/api/admission.js"
import ColorCodes from "../../../Utils/colorcodes"
import UniversityCourseAdd from "../../Admin/Organisation/UniversityCourseAdd.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import UniversityAdd from "../Organisation/UniversityAdd.vue"
import UniversityEdit from "../Organisation/UniversityEdit.vue"
import UniversityCourseList from "../ListPages/University Courses/UniversityCourseList.vue"

export default {
  name: "SpcmUnivList",
  props: {
    propSpcmObj: {
      type: Object,
      default: null
    },
    propUserUnivReco: {
      type: Array,
      default: null
    }
  },
  components: {
    UniversityCourseAdd,
    UniversityAdd,
    UniversityEdit,
    UniversityCourseList
  },
  data () {
    return {
      cvCardTitle: "University",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "University",
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "org_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "DRS", key: "org_drs", class: "text-left align-text-top", sortable: true },
        { label: "Application Deadline", key: "org_application_deadline", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Rank", key: "org_rank", class: "text-left align-text-top", sortable: true },
        { label: "Avail. Scholarships", key: "scholarship_id", class: "text-left align-text-top", sortable: true },
        { label: "Partnership", key: "is_partner", class: "text-left align-text-top", sortable: true },
        { label: "Acceptance Rate", key: "org_acceptance_rate", class: "text-left align-text-top", sortable: true },
        { label: "Tuition Fees", key: "org_avg_tuition_min", class: "text-left align-text-top w-100px", sortable: true },
        { label: "SAT Score", key: "org_sat_min", class: "text-left align-text-top w-100px", sortable: true },
        { label: "ACT Score", key: "org_act_min", class: "text-left align-text-top w-100px", sortable: true }
      ],
      univObjList: [],
      univFiltersList: [],
      courseNameList: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentBatchNo: 1,
      perPage: 200,
      cvbtnAddNewReco: "Add to Shortlist",
      cvbtnAddNewUniv: "Add",
      colorCodeList: ColorCodes.colorCodeList,
      whereFilter: {
        "country": "null",
        "is_partner": "null",
        "cour_name": "null"
      },
      univCourseSelectionModalTitle: "Select Course/Progam",
      modalCancelBtnText: "Cancel",
      cvUniversityNameLabel: "University Name",
      cvUniversityCourseNameLabel: "Course Name",
      showUnivCourseSelectionModal: false,
      showAddEditDeadlineModal: false,
      univCourseObj: {
        cour_id: null
      },
      univCourseObjectList: null,
      showModelUniversityCourseAdd: false,
      cvAddModalHeader: "Add Program/Course",
      showDefaultForRecommedUnivList: true,
      showLoadMoreBtn: true,
      showModelOrganisationAdd: false,
      showModelUniversityCourseList: false,
      showModelOrganisationEdit: false,
      cvAddUniAddHeader: "Add University",
      cvUniversityCourseHeader: "University Course List",
      cvbtnModalCancel: "close",
      cvEditModalHeader: "Edit Organisation",
      organisationEditObj: null,
      organisationCourseObj: null,
      vmOrganisationFormData: Object.assign({}, this.initFormData()),
      cvOrgDeadlineInternalLabel: "Application Deadline (Internal)",
      cvOrgDeadlineLabel: "University Application Deadlines",
      univDeadlineTitle: "Application Deadline (Internal)",
      organisationDetailObj: null,
      UnivObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.orgCourseFilterGet()
    await this.univFilterList("is_partner")
    await this.univFilterList("org_country")

    if (this.propSpcmObj && this.propSpcmObj.user_id) {
      // University reco list expanded
      this.columns.push({ label: "Course Details", key: "cour_name", class: "text-left align-text-top w-100px", sortable: true })
      this.showDefaultForRecommedUnivList = true
      this.getStudentAdmUnivDetailsList()
    }
    else {
      this.showDefaultForRecommedUnivList = false
      this.organisationList()
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "org_application_deadline": ""
      }
    },
    /**
     * organisationList
     */
    async organisationList (loadViaLoadMore = false) {
      try {
        let whereFilter = {}
        for (const property in this.whereFilter) {
          if (this.whereFilter[property] && this.whereFilter[property] != "null" && this.whereFilter[property] != " ") {
            whereFilter[property] = this.whereFilter[property]
          }
        }

        let filter = { type: ["UNIV"], user_role: this.userData.user_role, ...whereFilter, limit: this.perPage }
        if (this.whereFilter.search_param) {
          filter = { ...filter,
            where: {
              "OR":
                [
                  {
                    org_name: this.whereFilter.search_param
                  },
                  {
                    org_desc: this.whereFilter.search_param
                  },
                  {
                    org_city: this.whereFilter.search_param
                  },
                  {
                    org_country: this.whereFilter.search_param
                  },
                  {
                    org_founding_year: this.whereFilter.search_param
                  },
                  {
                    org_avg_tuition_min: this.whereFilter.search_param
                  },
                  {
                    org_avg_tuition_max: this.whereFilter.search_param
                  },
                  {
                    org_scholarship_min: this.whereFilter.search_param
                  },
                  {
                    org_scholarship_max: this.whereFilter.search_param
                  },
                  {
                    org_application_deadline: this.whereFilter.search_param
                  }
                ]
            }
          }
        }

        let univListResp = await Organisations.organisationList(this, filter, this.currentBatchNo)
        if (univListResp.resp_status) {
          if (loadViaLoadMore) {
            this.univObjList = [...this.univObjList, ...Object.values(univListResp.resp_data.data)]
          }
          else {
            this.univObjList = Object.values(univListResp.resp_data.data)
          }

          this.totalRows = this.univObjList.length
          this.showLoadMoreBtn = true
        }
        else {
          this.currentBatchNo--
          this.showLoadMoreBtn = false
          ApiResponse.responseMessageDisplay(this, univListResp)
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationList() and Exception:", err.message)
      }
    },
    /**
     * getStudentAdmUnivDetailsList
     */
    async getStudentAdmUnivDetailsList () {
      try {
        const postDataObj = {}
        if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
          postDataObj.gide_user_id = this.propSpcmObj.user_id
        }
        else {
          postDataObj.gide_user_id = this.userData.user_id
        }
        if (this.whereFilter.search_param) {
          postDataObj.search_param = this.whereFilter.search_param
        }
        postDataObj.currentBatchNo = this.currentBatchNo
        postDataObj.dataPerPage = this.perPage

        // Passing the filters (partnership_type, country, and course_name) for shortlisted universities too
        let whereFilter = {}
        for (const property in this.whereFilter) {
          if (this.whereFilter[property] && this.whereFilter[property] != "null" && this.whereFilter[property] != " ") {
            whereFilter[property] = this.whereFilter[property]
          }
        }
        postDataObj.filter = JSON.stringify(whereFilter)

        const studentUnivDetailsListResp = await Admissions.admissionStudentUniversityDetailsList(this, postDataObj)
        if (studentUnivDetailsListResp.resp_status) {
          this.univObjList = studentUnivDetailsListResp.resp_data.data
          this.totalRows = studentUnivDetailsListResp.resp_data.count
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo--
          ApiResponse.responseMessageDisplay(this, studentUnivDetailsListResp)
        }
      }
      catch (err) {
        console.error("Exception in getStudentAdmUnivDetailsList and err: ", err.message)
      }
    },
    /**
     * goToUnivEdit
     */
    goToUnivEdit (univObj) {
      window.open(`${process.env.VUE_APP_SERVER_URL}/organisation_edit/UNIV/${univObj.org_id}`)
    },
    /**
     * openUnivListModal
     */
    openUnivListModal () {
      this.$emit("emitOpenUnivListModal")
    },
    /**
     * Redirect to User Profile
    */
    gotoUserProfile (user) {
      try {
        window.open(`/user/${user.user_id}`)
      }
      catch (err) {
        console.error("Exception occurred at UserList() and Exception:", err.message)
      }
    },
    /**
     * openUnivCourseSelectionModal
     */
    async openUnivCourseSelectionModal (univObj) {
      // Get the courses of the university
      this.univCourseObj = univObj
      this.univCourseObj.cour_id = null
      const universityCourseList = await UniversityCourses.organisationCourseList(this, { org_id: univObj.org_id })

      if (universityCourseList.resp_status) {
        // Removing the already selected courses from the dropdown
        const courseObjList = universityCourseList.resp_data.data.filter((e) => {
          return !this.propUserUnivReco.find(admObj => {
            return admObj.cour_id && admObj.cour_id == e.cour_id
          })
        })

        this.univCourseObjectList = courseObjList
      }
      else {
        this.univCourseObjectList = null
      }

      this.showUnivCourseSelectionModal = true
      this.organisationView(univObj)
    },
    /**
     * closeUnivCourseSelectionModal
     */
    closeUnivCourseSelectionModal () {
      this.showUnivCourseSelectionModal = false
    },
    /**
     * addUnivToAdmission
     */
    async addUnivToAdmission () {
      if (!this.univCourseObj.cour_id) {
        this.toastTitle = "Course/Program"
        this.toastMsg = "Please select a course"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      const courseObj = this.univCourseObjectList.find(e => e.cour_id == this.univCourseObj.cour_id)
      const admData = { ...this.univCourseObj }
      admData.cour_degree_name = courseObj.cour_degree_name
      admData.cour_name = courseObj.cour_name
      this.$emit("emitAddUnivReco", admData)
      this.showUnivCourseSelectionModal = false
      this.showAddEditDeadlineModal = false

      this.organisationEdit()
    },
    /**
     * goToOrgAdd
     */
    goToOrgAdd () {
      this.showModelOrganisationAdd = true
    },
    /**
     * goToOrgEdit
     */
    goToOrgEdit (item) {
      this.organisationEditObj = item
      this.showModelOrganisationEdit = true
    },
    /**
     * goToOrganisationCourses
     */
    goToOrganisationCourses (item) {
      this.organisationCourseObj = item
      this.showModelUniversityCourseList = true
    },
    /**
     * getAcceptanceRate
     */
    getAcceptanceRate (rate) {
      let newrate = rate.includes("%") ? rate : `${rate}%`
      if (newrate == "0%") {
        newrate = "N/A"
      }

      return newrate
    },
    /**
     * getColorCode
     */
    getColorCode (index) {
      switch (index) {
        case "d":
          return ColorCodes.getBgColor(24)
        case "r":
          return ColorCodes.getBgColor(25)
        case "s":
          return ColorCodes.getBgColor(23)
        default:
          return ColorCodes.getBgColor(index)
      }
    },
    /*
    * univFilterList
    */
    async univFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let univFilterListResp = await Organisations.organisationFilterList(this, "UNIV", filterName)
        if (univFilterListResp) {
          this.univFiltersList[filterName] = univFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at univ_filter_list_resp() and Exception:", err.message)
      }
    },
    /**
     * setFilters
    */
    async setFilters () {
      this.currentBatchNo = 1
      if (this.propSpcmObj && this.propSpcmObj.user_id) {
        // University reco list expanded
        this.showDefaultForRecommedUnivList = true
        await this.getStudentAdmUnivDetailsList()
      }
      else {
        this.showDefaultForRecommedUnivList = false
        await this.organisationList()
      }
    },
    /**
     * addCourseToUniv
     */
    addCourseToUniv (item) {
      this.UnivObj = item
      this.cvAddModalHeader += ` For ${this.univCourseObj.org_name}`
      this.showModelUniversityCourseAdd = true
    },
    /*
    * orgCourseFilterGet
    */
    async orgCourseFilterGet () {
      try {
        let orgFilterListResp = await UniversityCourses.organisationCourseFilterGet()
        if (orgFilterListResp) {
          this.courseNameList = orgFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at orgCourseFilterGet() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityCourseAddModal
     */
    closeUniversityCourseAddModal (organisationCourseAddData) {
      try {
        if (this.univCourseObjectList) {
          this.univCourseObjectList.unshift(organisationCourseAddData)
        }
        else {
          this.univCourseObjectList = [organisationCourseAddData]
        }

        this.showModelUniversityCourseAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeorganisationCourseAddModal() and Exception:", err.message)
      }
      this.showModelUniversityCourseAdd = false
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo++

      if (this.propSpcmObj && this.propSpcmObj.user_id) {
        // University reco list expanded
        this.showDefaultForRecommedUnivList = true
        this.getStudentAdmUnivDetailsList(true)
      }
      else {
        this.showDefaultForRecommedUnivList = false
        this.organisationList(true)
      }
    },
    /**
      * searchFromBackend
    */
    searchFromBackend () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }

      this.currentBatchNo = 1
      if (this.propSpcmObj && this.propSpcmObj.user_id) {
        // University reco list expanded
        this.showDefaultForRecommedUnivList = true
        this.getStudentAdmUnivDetailsList()
      }
      else {
        this.showDefaultForRecommedUnivList = false
        this.organisationList()
      }
    },
    /**
     * openUnivDetails
     */
    openUnivDetails (orgObj) {
      window.open(`/organisations/UNIV/${orgObj.org_id}`)
    },
    /**
     * closeOrganisationAddModal
     */
    closeOrganisationAddModal (organisationAddData) {
      try {
        if (organisationAddData) {
          if (this.organisationObjList && this.organisationObjList.length >= 1) {
            let organisationObjLength = this.organisationObjList.length
            let lastId = this.organisationObjList[organisationObjLength - 1]["id"]
            organisationAddData.id = lastId + 1
          }
          else {
            this.organisationObjList = []
            organisationAddData.id = 11111
          }

          organisationAddData.created_on = moment()
          this.organisationObjList.unshift(organisationAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelOrganisationAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeorganisationAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeOrganisationEditModal
     */
    closeOrganisationEditModal () {
      try {
        this.showModelOrganisationEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeOrganisationEditModal() and Exception:", err.message)
      }
    },
    /**
     * closeOrganisationEditModal
     */
    closeUniversityCourseListModal () {
      try {
        this.showModelUniversityCourseList = false
      }
      catch (err) {
        console.error("Exception occurred at closeUniversityCourseListModal() and Exception:", err.message)
      }
    },
    /**
     * goToUnivCoursePage
     */
    goToUnivCoursePage (courUrl) {
      window.open(courUrl, "_blank")
    },
    /*
     * goto Activity URL
     */
    gotoActivityURL (url) {
      if (url) {
        window.open(url, "_blank")
      }
    },
    /**
     * organisationEditDeadline
     */
    organisationEditDeadline () {
      this.organisationEdit()
      this.showAddEditDeadlineModal = false
    },
    /**
     * organisationAdd
     */
    async organisationEdit () {
      try {
        let payload = {
          org_id: this.vmOrganisationFormData.org_id,
          type: "UNIV",
          org_application_deadline_internal: this.vmOrganisationFormData.org_application_deadline_internal
        }

        if (this.vmOrganisationFormData.org_application_deadline) {
          payload.org_application_deadline = this.vmOrganisationFormData.org_application_deadline
        }

        let response = await Organisations.organisationEdit(this, payload)
        if (response && response.resp_status) {
          this.vmOrganisationFormData = Object.assign({}, this.initFormData())
          this.organisationList()
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * openInternalAndExternalDeadline
    */
    async openInternalAndExternalDeadline (item) {
      this.organisationView(item.item)
      this.organisationDetailObj = item.item
      this.showAddEditDeadlineModal = true
    },
    /**
     * closeAddEditDeadlineModalnModal
    */
    closeAddEditDeadlineModalnModal () {
      this.showUnivCourseSelectionModal = false
    },
    /**
     * organisationView
    */
    async organisationView (univObj) {
      try {
        let organisationId = univObj.org_id
        let organisationViewResp = await Organisations.organisationView(this, organisationId, null, this.userData.user_role)
        if (organisationViewResp && organisationViewResp.resp_status) {
          this.vmOrganisationFormData = organisationViewResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationView() and Exception:", err.message)
      }
    },
    /**
     * getApplicationDeadlines
    */
    getApplicationDeadlines (orgObj) {
      try {
        let deadlines = orgObj.org_application_deadline
        let deadlinesObj = null
        let deadlineStr = ""

        if (!deadlines) {
          deadlineStr += `<small>Deadlines Not Found</small><br>`
        }
        else if (deadlines.includes("{")) {
          deadlinesObj = JSON.parse(deadlines)
          for (const key in deadlinesObj) {
            const value = deadlinesObj[key]
            deadlineStr += `<small>${key}: ${value}</small><br>`
          }
        }
        else {
          deadlineStr += `<small>${deadlines}</small><br>`
        }

        return deadlineStr
      }
      catch (e) {
        console.error("Ex. getApplicationDeadlines:", e)
      }
    }
  }
}
</script>
