/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>

          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <ValidationObserver ref="univProfileForm">
              <form action="#">
                <div class="form-row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <label for="validationorg_name">{{cvOrgNameLabel}}
                          <span class="primary-color font-size-16" style="line-height: 0px;">* </span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgNameLabel"
                          rules="required|max:50"
                          v-slot="{ errors }">
                          <input v-model="vmOrganisationFormData.org_name" :placeholder="cvOrgNameLabel" type="text" class="form-control" required  />
                          <span class="text-danger">
                            <span v-if="vmOrganisationFormData.org_name && errors[0]"> Name may not be more than 50 characters. </span>
                            <span v-else> {{ errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <ValidationProvider
                          :name="cvOrgAdminEmailLabel"
                          rules="required|email"
                          v-slot="{ errors }">
                          <label for="validationorg_admin_email">
                            {{cvOrgAdminEmailLabel}}
                            <span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                          </label>
                          <input
                            v-model="vmOrganisationFormData.org_admin_email"
                            type="text"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">{{errors[0]}} </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <label for="validationorg_type">{{cvUnivTypeLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvUnivTypeLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <select v-model="vmOrganisationFormData.univ_type" class="form-control">
                            <option v-for="(Orgtype, index) of OrgType" :key="index" :value="Orgtype">
                              {{Orgtype}}
                            </option>
                          </select>
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_city">{{cvOrgCityLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgCityLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_city"
                            type="text"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_state">{{cvOrgStateLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgStateLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_state"
                            type="text"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_country">{{cvOrgCountryLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgCountryLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <multiselect v-model="vmOrganisationFormData.org_country" :tag-placeholder="cvSelectLabel + cvOrgCountryLabel"
                          :placeholder="cvSelectLabel + cvOrgCountryLabel"
                          label="country_name" track-by="country_name"
                          :searchable="true"
                          :options="cvCountryOptions">
                          </multiselect>
                        <span class="text-danger">
                          <span>{{errors[0]}} </span>
                        </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_currency">{{cvOrgCurrencyLabel}} <small>(INR, USD, Euro)</small>
                          <span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgCurrencyLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_currency"
                            type="text"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_url">{{cvOrgUrlLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgUrlLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_url"
                            type="url"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_founding_year">{{cvOrgFoundingYearLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_founding_year"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                        <label for="validationorg_admission_requirement">{{cvOrgAdmissionRequirementLabel}}</label>
                        <vue-editor
                          v-model="vmOrganisationFormData.org_admission_requirement"
                          type="text"
                          required>
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_rank">{{cvOrgRankLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_rank"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_drs">{{cvOrgDrsLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span></label>
                        <ValidationProvider
                          :name="cvOrgDrsLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <select v-model="vmOrganisationFormData.org_drs" class="form-control">
                            <option v-for="(drsLabel, drsCode) of orgDrsObj" :key="drsCode" :value="drsCode">
                              {{drsLabel}}
                            </option>
                          </select>
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                        <label for="validationis_partner">{{cvIsPartnerLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvIsPartnerLabel"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input v-model="vmOrganisationFormData.is_partner" type="text" class="form-control" placeholder="Ex. Direct, Indirect, SIUK, etc" required />
                          <span class="text-danger">
                            <span>{{errors[0]}}</span>
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-3">
                        <label for="validationorg_priority">{{cvOrgPriorityLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_priority"
                          placeholder="Ex. 1, 2, 500"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                        <label for="validationorg_desc">{{cvOrgDescLabel}}</label>
                        <vue-editor
                          v-model="vmOrganisationFormData.org_desc"
                          type="text"
                          required>
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                        <label for="mobile">{{ cvUserMobileNoLabel }}</label>
                          <ValidationProvider
                            name="Mobile no"
                            rules="required"
                            v-slot="{ errors }">
                            <input
                              type="number"
                              class="form-control"
                              v-model="vmOrganisationFormData.org_mobile"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_website_email">
                      {{cvOrgWebsiteEmailLabel}}</label
                    >
                    <ValidationProvider
                      :name="cvOrgAdminEmailLabel"
                      rules="required|email"
                      v-slot="{ errors }">
                      <input
                        v-model="vmOrganisationFormData.org_website_email"
                        type="email"
                        class="form-control"
                        required
                      />
                      <span class="text-danger">{{errors[0]}} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                        <label for="validationorg_clubs">{{cvOrgClubsLabel}}</label>
                        <vue-editor
                          v-model="vmOrganisationFormData.org_clubs"
                          type="text"
                          required>
                        </vue-editor>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                        <label for="validationorg_top_alums">{{cvOrgTopAlumsLabel}}</label>
                        <vue-editor
                          v-model="vmOrganisationFormData.org_top_alums"
                          type="text"
                          required>
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_deadline">{{cvOrgDeadlineLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_application_deadline"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_intake_count">{{cvOrgIntakeCountLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_intake_count"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_intake_type">{{cvOrgIntakeTypeLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_intake_type"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                        <label for="validationorg_avg_tuition_min">{{cvOrgAvgTuitionMinLabel}}
                          <span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgAvgTuitionMinLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_avg_tuition_min"
                            type="number"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                      <div class="col-12 col-md-6 mb-3">
                        <label for="validationorg_avg_tuition_max">{{cvOrgAvgTuitionMaxLabel}}
                          <span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgAvgTuitionMaxLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.org_avg_tuition_max"
                            type="number"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_scholarship_min">{{cvOrgScholarshipMinLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_scholarship_min"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="validationorg_scholarship_max">{{cvOrgScholarshipMaxLabel}}</label>
                        <input
                          v-model="vmOrganisationFormData.org_scholarship_max"
                          type="number"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                        <label for="validationorg_major_industries_around">{{cvOrgMajorIndustriesAroundLabel}}</label>
                        <vue-editor
                          v-model="vmOrganisationFormData.org_major_industries_around"
                          type="text"
                          required>
                        </vue-editor>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                        <label for="validationtier_grade">{{cvTierGradeLabel}}<span class="primary-color font-size-16" style="line-height: 0px;">*</span>
                        </label>
                        <ValidationProvider
                          :name="cvOrgAvgTuitionMinLabel"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmOrganisationFormData.tier_grade"
                            type="number"
                            class="form-control"
                            required
                          />
                          <span class="text-danger">
                            <span>{{errors[0]}} </span>
                          </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_sat_min">{{cvOrgSatMinLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_sat_min"
                      type="number"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_sat_max">{{cvOrgSatMaxLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_sat_max"
                      type="number"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_act_min">{{cvOrgActMinLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_act_min"
                      type="number"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_act_max">{{cvOrgActMaxLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_act_max"
                      type="number"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationorg_acceptance_rate">{{cvOrgAcceptanceRateLabel}}</label>
                    <input
                      v-model="vmOrganisationFormData.org_acceptance_rate"
                      type="number"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <div class="d-inline-block col-12 w-100">
                      <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                        <ValidationProvider
                        name="Terms and Condition"
                        rules="required"
                        v-slot="{ errors }">
                          <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="vmOrganisationFormData.org_tnc">
                          <label class="custom-control-label" for="customCheck1">I have read and accept the <a href="/content/Tnc" target="_blank">Terms of Use</a>
                          </label><br>
                          <span class="text-danger"> {{ errors[0]}} </span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="organisationAdd()"
                  >
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { Organisations } from "../../../FackApi/api/organisation"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { ValidationProvider } from "vee-validate"
import { VueEditor } from "vue2-editor"
import { Countries } from "../../../FackApi/api/country"
import Multiselect from "vue-multiselect"

export default {
  name: "OrganisationAdd",
  components: {
    ValidationProvider,
    VueEditor,
    Multiselect
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add organisation",
      cvCardSubHeader: "Add organisation sub header",
      cvSubmitBtn: "Add",
      cvOrgNameLabel: "name",
      cvTypeLabel: "Type",
      cvUnivTypeLabel: "type",
      cvOrgDescLabel: "description",
      cvOrgWebsiteEmailLabel: "website email",
      cvOrgAdminEmailLabel: "admin email",
      cvUserMobileNoLabel: "Mobile Number",
      cvOrgCityLabel: "city",
      cvOrgStateLabel: "state",
      cvOrgCountryLabel: "country",
      cvOrgFoundingYearLabel: "founding year",
      cvOrgDeadlineLabel: "deadline",
      cvOrgIntakeCountLabel: "intake count",
      cvOrgIntakeTypeLabel: "intake type",
      cvOrgCurrencyLabel: "currency",
      cvOrgUrlLabel: "url",
      cvOrgClubsLabel: "clubs",
      cvOrgAdmissionRequirementLabel: "admission requirement",
      cvOrgAvgTuitionMinLabel: "avg tuition min score",
      cvOrgAvgTuitionMaxLabel: "avg tuition max score",
      cvOrgScholarshipMinLabel: "scholarship min score",
      cvOrgScholarshipMaxLabel: "scholarship max score",
      cvOrgTopAlumsLabel: "top alums",
      cvOrgMajorIndustriesAroundLabel: "major industries around",
      cvOrgPriorityLabel: "Display Priority",
      cvOrgRankLabel: "rank",
      cvOrgDrsLabel: "DRS",
      cvIsPartnerLabel: "Partnership",
      cvTierGradeLabel: "tier grade",
      cvOrgTncLabel: "tnc",
      cvOrgSatMinLabel: "sat min score",
      cvOrgSatMaxLabel: "sat max score",
      cvOrgActMinLabel: "act min score",
      cvOrgActMaxLabel: "act max score",
      cvOrgAcceptanceRateLabel: "acceptance rate",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Added",
      vmOrganisationFormData: Object.assign({}, this.initFormData()),
      errMsg: "",
      cvSelectLabel: "Select ",
      cvCountryOptions: [],
      univFiltersList: [],
      showTncErrBox: false,
      OrgType: {
        "private": "Private",
        "public": "Public",
        "iac": "IAC"
      },
      orgDrsObj: {
        "d": "Dream",
        "r": "Reach",
        "s": "Safe"
      }
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    await this.univFilterList("is_partner")
    this.countryDistinctList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "org_name": "",
        "univ_type": "",
        "org_desc": "",
        "org_website_email": "",
        "org_admin_email": "",
        "org_city": "",
        "org_state": "",
        "org_country": "",
        "org_founding_year": "",
        "org_application_deadline": "",
        "org_intake_count": "",
        "org_intake_type": "",
        "org_currency": "",
        "org_url": "",
        "org_clubs": "",
        "org_admission_requirement": "",
        "org_avg_tuition_min": "",
        "org_avg_tuition_max": "",
        "org_scholarship_min": "",
        "org_scholarship_max": "",
        "org_top_alums": "",
        "org_major_industries_around": "",
        "org_priority": "",
        "org_rank": "",
        "org_drs": "",
        "is_partner": "",
        "tier_grade": "",
        "org_tnc": "",
        "org_sat_min": "",
        "org_sat_max": "",
        "org_act_min": "",
        "org_act_max": "",
        "org_acceptance_rate": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmOrganisationFormData) {
          if (!this.vmOrganisationFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }

        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        let countryIndex = this.cvCountryOptions.findIndex(country => country.country_name === this.vmOrganisationFormData.org_country)
        if (countryIndex >= 0) {
          this.vmOrganisationFormData.org_country = this.cvCountryOptions[countryIndex]
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * organisationAdd
     */
    async organisationAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      let valid = await this.$refs.univProfileForm.validate()
      if (!valid) {
        return
      }

      // if (!this.tnc) {
      //   this.errMsg = "Tnc is required"
      //   this.showTncErrBox = true
      //   return false
      // }
      try {
        this.vmOrganisationFormData.type = "UNIV"
        this.vmOrganisationFormData.org_country = this.vmOrganisationFormData.org_country.country_name
        this.cvLoadingStatus = true
        let organisationAddResp = await Organisations.organisationAdd(this, this.vmOrganisationFormData)
        await ApiResponse.responseMessageDisplay(this, organisationAddResp)
        if (organisationAddResp && !organisationAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseOrganisationAddModal", organisationAddResp.resp_data)
        }
        this.vmOrganisationFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at organisationAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /*
    * univFilterList
    */
    async univFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let univFilterListResp = await Organisations.organisationFilterList(this, "UNIV", filterName)
        if (univFilterListResp) {
          this.univFiltersList[filterName] = univFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at univ_filter_list_resp() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
  @media (min-width:992px) {
  .d-none-lg{
    display: none;
  }
}
</style>
