<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card :class="!propUnivDetail ? '' : 'modal_Header_spacing'">
          <template v-slot:headerTitle>
            <b-row class="m-0">
              <b-col class="col-12 col-sm-8 col-md-10 col-lg-10">
                <h4 class="card-title">{{cvCardTitle}}</h4>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-2">
                <b-button class="pull-right mt-2" variant="primary" @click="organisationCourseAdd()">{{cvbtnAddNew}}</b-button>
              </b-col>
              <b-col class="col-12 mb-2">
                <span>{{ orgName }}</span>
              </b-col>
            </b-row>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="organisationCourseObjList && organisationCourseObjList.length > 0">
                <b-table hover responsive :items="organisationCourseObjList" :fields="columns" :no-border-collapse="true"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  :current-page="currentPage" :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(org_id)="data">
                    <div>
                      <p v-html="data.item.org_id" style="word-wrap: break-word">
                      </p>
                    </div>
                  </template>
                  <template v-slot:cell(cour_name)="data">
                    <p v-html="data.item.cour_name" style="word-wrap: break-word; cursor: pointer;">
                    </p>
                    <b-button class="btn btn actionIcon mr-1 mb-1 btn-sm pointer" size="sm" @click="organisationCourseEdit('MODAL', data.item)" v-if="!data.item.editable">
                        <i class="ri-ball-pen-fill font-size-20 primary-color m-0"></i>
                    </b-button>
                    <b-button class="btn btn actionIcon mr-1 mb-1 btn-sm pointer" size="sm" @click="showUniversityCourseDeleteDialog(data.item)">
                      <i class="ri-delete-bin-line font-size-20 primary-color m-0"></i>
                    </b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="gotoActivityURL(data.item.url)" title=""><i class="fa-brands fa-chrome m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button><br>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelUniversityCourseAdd" scrollable :title="cvAddModalHeader" size="xl">
      <UniversityCourseAdd :propOpenedInModal="true" :propUnivDetail = "propUnivDetail"
        @emitCloseUniversityCourseAddModal="closeUniversityCourseAddModal" />
      <template >
        <b-button size="sm" class="pull-left" @click="organisationCourseAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUniversityCourseEdit" scrollable :title="cvEditModalHeader" size="xl">
      <UniversityCourseEdit :propOpenedInModal="true" :propUniversityCourseObj="organisationCourseEditObj"
        @emitCloseUniversityCourseEditModal="closeUniversityCourseEditModal" />
      <template >
        <b-button size="sm" class="pull-left" @click="organisationCourseEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUniversityCourseDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUniversityCourseDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="organisationCourseDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { UniversityCourses } from "../../../../FackApi/api/universityCourse.js"
import UniversityCourseAdd from "./UniversityCourseAdd.vue"
import UniversityCourseEdit from "./UniversityCourseEdit.vue"
import CsvUpload from "../../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../../components/Csv/CsvDownload.vue"
import moment from "moment"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { socialvue } from "../../../../config/pluginInit.js"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "UniversityCourseList",
  props: {
    propUnivDetail: {
      type: Object,
      default: null
    }
  },
  components: {
    UniversityCourseAdd,
    UniversityCourseEdit,
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      apiName: "organisation_course_list",
      cvCardTitle: "University Courses",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit University Course",
      cvAddModalHeader: "Add University Course",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      cvOrgId: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvModuleFile: "organisation_course",
      cvModulePrefix: "org_course",
      toastTitle: "University Course",
      showModelUniversityCourseAdd: false,
      showModelUniversityCourseEdit: false,
      showModelUniversityCourseDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Degree", key: "cour_degree_name", class: "text-left", sortable: true },
        { label: "course", key: "cour_name", class: "text-left", sortable: true },
        { label: "Currency", key: "cour_curr", class: "text-left", sortable: true },
        { label: "Tuition Min", key: "cour_tuition_min", class: "text-left", sortable: true },
        { label: "Tuition Max", key: "cour_tuition_max", class: "text-left", sortable: true },
        { label: "Scholarship Min", key: "cour_scholarship_min", class: "text-left", sortable: true },
        { label: "Scholarship Max", key: "cour_scholarship_max", class: "text-left", sortable: true },
        { label: "Admission Cycle", key: "cour_adm_cycle", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true }
      ],
      organisationCourseObjList: null,
      organisationCourseEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      orgName: ""
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.cvOrgId = this.$route.params.org_id
    this.organisationCourseList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseList
     */
    async organisationCourseList () {
      try {
        let payload = {}

        if (this.propUnivDetail) {
          payload.org_id = this.propUnivDetail.org_id
        }
        if (this.cvOrgId) {
          payload.org_id = this.cvOrgId
        }
        let organisationCourseListResp = await UniversityCourses.organisationCourseList(this, payload)
        if (organisationCourseListResp.resp_status) {
          this.organisationCourseObjList = organisationCourseListResp.resp_data.data
          this.orgName = this.organisationCourseObjList[0].org_name
          this.totalRows = organisationCourseListResp.resp_data.count
        }
        else {
          this.toastMsg = organisationCourseListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at organisationCourseList() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseAdd
     */
    organisationCourseAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/organisationCourse_add")
        }
        else {
          this.showModelUniversityCourseAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseAdd() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseEdit
     */
    organisationCourseEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/organisationCourse_edit/" + this.organisationCourseEditObj.cour_id)
        }
        else {
          this.organisationCourseEditObj = item
          this.showModelUniversityCourseEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseEdit() and Exception:", err.message)
      }
    },
    /**
     * showUniversityCourseDeleteDialog
     */
    showUniversityCourseDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUniversityCourseDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUniversityCourseDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseDelete
     */
    async organisationCourseDelete () {
      try {
        let organisationCourseDelResp = await UniversityCourses.organisationCourseDelete(this, this.delObj.cour_id)
        await ApiResponse.responseMessageDisplay(this, organisationCourseDelResp)

        if (organisationCourseDelResp && !organisationCourseDelResp) {
          this.showModelUniversityCourseDelete = false
          return false
        }

        let index = this.organisationCourseObjList.indexOf(this.delObj)
        this.organisationCourseObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUniversityCourseDelete = false
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseDelete() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityCourseAddModal
     */
    closeUniversityCourseAddModal (organisationCourseAddData) {
      try {
        if (organisationCourseAddData) {
          if (this.organisationCourseObjList && this.organisationCourseObjList.length >= 1) {
            let courObjLength = this.organisationCourseObjList.length
            let lastId = this.organisationCourseObjList[courObjLength - 1]["id"]
            organisationCourseAddData.id = lastId + 1
          }
          else {
            this.organisationCourseObjList = []
            organisationCourseAddData.id = 11111
          }

          organisationCourseAddData.created_on = moment()
          this.organisationCourseObjList.unshift(organisationCourseAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelUniversityCourseAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeorganisationCourseAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityCourseEditModal
     */
    closeUniversityCourseEditModal () {
      try {
        this.showModelUniversityCourseEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUniversityCourseEditModal() and Exception:", err.message)
      }
    },
    /*
     *gotoActivityURL
     */
    gotoActivityURL (url) {
      window.open(url, "_black")
    }
  }
}
</script>
